import { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";

import "./style.scss";
import { FirebaseAuthContainer } from "../../../common/containers/auth/firebase-auth-container.util";
import { ReactComponent as UploadIcon } from "../../../assets/uploadIcon.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/documentIcon.svg";
import { ReactComponent as DocumentErrorIcon } from "../../../assets/documentErrorIcon.svg";
import { MimeType, TrialMimeType } from "../../../common/enums/mime-type.enum";
import { ProviderType } from "../../../common/enums/provider-type.enum";
import { Provider } from "../../../common/interfaces/admin/provider.interface";
import { PresignedUrl } from "../../../common/interfaces/admin/presigned-url.interface";
import {
    UploadableFile,
    UploadableFileStatus
} from "../../../common/interfaces/admin/uploadable-file.interface";
import {
    fetchPresignedUrls,
    uploadFile,
    cancelFile,
    confirmFile
} from "../../../api/admin/content-providers/endpoints-referrers";
import {
    fetchProviders,
    fetchContentFolders,
    fetchLearningObjects
} from "../../../api/admin/content-providers/endpoints-referrers";
import { ModalTemplate } from "../../../components/modal-template/modal-template.comp";
import { ProgressBar, ProgressBarType } from "../../../components/progress-bar/progress-bar.comp";
import { LoaderStep } from "../../../common/enums/loader-step.enum";

const FileUploadView = () => {
    const maxTrialFileCount = 10;
    const UPLOAD_TITLE = `Upload file
    to the Knowledge Base`;
    const START_DESCRIPTION = `Supported file formats: pdf, txt, mp3, mp4
    File size should be no more than 5 Gb`;
    const TRIAL_DESCRIPTION = `Max ${maxTrialFileCount} files in trial version. Supported file formats: pdf, txt, doc, xls
    Mp3, mp4 and docx are available in full version. `;
    const LOADING_DESCRIPTION = `Do not close this window until downloads are complete.
    Otherwise, downloads will be terminated.`;
    const COMPLETE_DESCRIPTION = `Upload complete. You may now close this window.`;

    const navigate = useNavigate();
    const { isTrial, user, knowledgeBases } = FirebaseAuthContainer.useContainer();
    const inputFile = useRef<HTMLInputElement | null>(null);
    const [files, setFiles] = useState<UploadableFile[]>([]);
    const [drop, setDrop] = useState(false);
    const [step, setStep] = useState<LoaderStep>(LoaderStep.start);
    const [error, setError] = useState<string>("");
    const [providerId, setProviderId] = useState<number>();
    const [courseId, setCourseId] = useState<string>();
    const [mimeTypes, setMineTypes] = useState<string[]>([]);
    const [, setFinishedRequestCount] = useState<number>(0);
    const [uploadedFileCount, setUploadedFileCount] = useState<number>(0);

    useEffect(() => {
        setMineTypes(Object.values(isTrial ? TrialMimeType : MimeType));
    }, [isTrial]);

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault();
        };

        if (step == LoaderStep.loading) {
            window.addEventListener("beforeunload", handleBeforeUnload);
        }

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [step]);

    useEffect(() => {
        if (!user) return;

        fetchProviders(user.companyId).then(response => {
            const provider = response.data.find(
                (item: Provider) => item.name === ProviderType.fileUploader
            );
            if (provider) {
                setProviderId(provider.id);
            }
        });
    }, [user]);

    useEffect(() => {
        if (!user || !providerId) return;

        fetchContentFolders(user.companyId, providerId).then(response => {
            if (response.data.length && response.data[0].courses.length) {
                setCourseId(response.data[0].courses[0].courseId);
            }
        });
    }, [user, providerId]);

    useEffect(() => {
        if (!user || !courseId || !isTrial) return;

        const query = "?page=1&pageSize=10";
        fetchLearningObjects(user.companyId, courseId, query).then(response => {
            const {
                data: { count }
            } = response;
            setUploadedFileCount(count);
        });
    }, [user, courseId, isTrial]);

    const handleCloseClick = () => {
        navigate(-1);
    };

    const handleTryAgainClick = () => {
        setFiles(prevFiles =>
            prevFiles.map(file => ({
                ...file,
                loadingPercent: 0,
                loadingStatus: UploadableFileStatus.start
            }))
        );
        setError("");
        setStep(LoaderStep.start);
    };

    const openFilePicker = () => {
        inputFile?.current?.click();
    };

    const isAvailableFileSize = (size: number) => {
        return size < 5368709120;
    };

    const isAvailableFileType = (type: string) => {
        return !!mimeTypes.find(mimeType => mimeType === type);
    };

    const supportedFiles = () => {
        return files.filter(file => isAvailableFileType(file.fileData.type));
    };

    const isAvailableUpload = () => {
        return !!supportedFiles().length;
    };

    const warningFileTitle = (file: File) => {
        return !isAvailableFileSize(file.size)
            ? "File size is too large"
            : !isAvailableFileType(file.type)
              ? "Unsupported file format"
              : "";
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;

        handleFiles([...event.target.files]);

        if (inputFile?.current) {
            inputFile.current.value = "";
        }
    };

    const onDragLeave = (e: React.DragEvent<HTMLElement>) => {
        if (step === LoaderStep.loading) return;
        e.preventDefault();
        setDrop(false);
    };

    const onDragOver = (e: React.DragEvent<HTMLElement>) => {
        if (step === LoaderStep.loading) return;
        e.preventDefault();
        setDrop(true);
    };

    const handleDrop = (e: React.DragEvent<HTMLElement>) => {
        if (step === LoaderStep.loading) return;
        e.preventDefault();
        const droppedFiles = [...e.dataTransfer.files];
        setDrop(false);

        handleFiles(droppedFiles);
    };

    const handleFiles = (files: File[]) => {
        setFiles(prevFiles => {
            const filteredFiles = files
                .filter(
                    file =>
                        !prevFiles.find(
                            item =>
                                file.name === item.fileData.name && file.size === item.fileData.size
                        )
                )
                .map(file => ({
                    uid: crypto.randomUUID(),
                    fileData: file,
                    loadingPercent: 0,
                    loadingStatus: !isAvailableFileSize(file.size)
                        ? UploadableFileStatus.tooLarge
                        : !isAvailableFileType(file.type)
                          ? UploadableFileStatus.unsupported
                          : UploadableFileStatus.start
                }));
            const fileList =
                !isTrial || isTrial
                    ? filteredFiles
                    : filteredFiles.slice(
                          0,
                          maxTrialFileCount - uploadedFileCount - prevFiles.length
                      );
            return [...prevFiles, ...fileList];
        });
    };

    const fileSize = (size: number) => {
        return size < 1024
            ? `${size} B`
            : size < 1048576
              ? `${Math.ceil(size / 1024)} Kb`
              : size < 1073741824
                ? `${(size / 1048576).toFixed(1)} Mb`
                : `${(size / 1073741824).toFixed(1)} Gb`;
    };

    const isErrorFileStatus = (status: UploadableFileStatus) => {
        return (
            status === UploadableFileStatus.error ||
            status === UploadableFileStatus.tooLarge ||
            status === UploadableFileStatus.unsupported
        );
    };

    const progressType = (status: UploadableFileStatus) => {
        switch (status) {
            case UploadableFileStatus.start:
                return ProgressBarType.start;
            case UploadableFileStatus.loading:
                return ProgressBarType.loading;
            case UploadableFileStatus.complete:
                return ProgressBarType.success;
            case UploadableFileStatus.error:
                return ProgressBarType.error;
            case UploadableFileStatus.cancel:
                return ProgressBarType.cancel;
            case UploadableFileStatus.tooLarge:
            case UploadableFileStatus.unsupported:
                return ProgressBarType.warning;
        }
    };

    const updateFileStatus = (uid: string, status: UploadableFileStatus) => {
        setFiles(prevFiles =>
            prevFiles.map(prevFile =>
                prevFile.uid === uid
                    ? {
                          ...prevFile,
                          loadingStatus: status
                      }
                    : prevFile
            )
        );
    };

    const increaseFinishedRequestCount = () => {
        setFinishedRequestCount(prevCount => {
            const count = prevCount + 1;
            if (count === supportedFiles().length) {
                setStep(LoaderStep.complete);
            }
            return count;
        });
    };

    const handleCancelClick = (file: UploadableFile) => {
        if (file.loadingStatus === UploadableFileStatus.loading) {
            file.controller?.abort();

            if (!user || !providerId) return;
            cancelFile(user.companyId, providerId, file.uid)
                .then(() => {
                    updateFileStatus(file.uid, UploadableFileStatus.cancel);
                })
                .catch(() => {
                    updateFileStatus(file.uid, UploadableFileStatus.error);
                })
                .finally(() => {
                    increaseFinishedRequestCount();
                });
        } else {
            setFiles(prevFiles => prevFiles.filter(prevFile => prevFile.uid !== file.uid));
        }
    };

    const handleUploadFileClick = () => {
        if (!user || !supportedFiles().length || !knowledgeBases.length || !courseId || !providerId)
            return;
        // TODO: get from page path params. User should choose it on Knowledge Bases page
        const knowledgeBase = knowledgeBases[0];

        setStep(LoaderStep.loading);
        fetchPresignedUrls(user.companyId, providerId, {
            knowledgeBaseId: knowledgeBase.uid,
            courseId: courseId,
            files: supportedFiles().map(file => ({
                originalname: file.fileData.name,
                mimetype: file.fileData.type,
                size: file.fileData.size
            }))
        })
            .then(response => {
                const urls: PresignedUrl[] = response.data;

                if (!urls.length) {
                    setError("Something went wrong");
                    setStep(LoaderStep.error);
                }

                const uploadableFiles = files.map(file => {
                    const url = urls.find(url => file.fileData.name === url.originalname);
                    return !url
                        ? {
                              ...file,
                              loadingStatus: UploadableFileStatus.unsupported
                          }
                        : {
                              ...file,
                              uid: url.id,
                              url: url.url,
                              header: url.headers,
                              loadingStatus: UploadableFileStatus.loading,
                              controller: new AbortController()
                          };
                });
                setFinishedRequestCount(0);
                setFiles(uploadableFiles);
                uploadableFiles.forEach(file => {
                    if (file.url && file.header) {
                        const onUploadProgress = (progressEvent: ProgressEvent) => {
                            const { loaded, total } = progressEvent;
                            const percentage = Math.floor((loaded * 100) / total);
                            setFiles(prevFiles =>
                                prevFiles.map(prevFile =>
                                    prevFile.uid === file.uid
                                        ? { ...prevFile, loadingPercent: percentage }
                                        : prevFile
                                )
                            );
                        };

                        uploadFile(file.url, file.fileData, {
                            headers: file.header,
                            signal: file.controller?.signal,
                            onUploadProgress: onUploadProgress
                        })
                            .then(() => {
                                confirmFile(user.companyId, providerId, file.uid)
                                    .then(() => {
                                        updateFileStatus(file.uid, UploadableFileStatus.complete);
                                    })
                                    .catch(() => {
                                        updateFileStatus(file.uid, UploadableFileStatus.error);
                                    })
                                    .finally(() => {
                                        increaseFinishedRequestCount();
                                    });
                            })
                            .catch(e => {
                                if (e?.code !== "ERR_CANCELED") {
                                    updateFileStatus(file.uid, UploadableFileStatus.error);
                                    increaseFinishedRequestCount();
                                    cancelFile(user.companyId, providerId, file.uid);
                                }
                            });
                    } else if (file.loadingStatus !== UploadableFileStatus.unsupported) {
                        updateFileStatus(file.uid, UploadableFileStatus.error);
                        increaseFinishedRequestCount();
                    }
                });
            })
            .catch(e => {
                setError(e?.message ?? "Something went wrong");
                setStep(LoaderStep.error);
            });
    };

    return (
        <div
            className="file-upload-area"
            onDrop={handleDrop}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
        >
            <input
                ref={inputFile}
                type="file"
                multiple
                onChange={handleFileChange}
                accept={isTrial ? ".txt, .pdf, .doc, .docx, .xls, .xlsx" : ".txt, .pdf, .mp3, .mp4"}
            />
            <ModalTemplate height={455} handleClose={handleCloseClick}>
                <div className="file-upload">
                    {step !== LoaderStep.error && (
                        <>
                            <div className="file-upload-title">{UPLOAD_TITLE}</div>
                            <div
                                className={`file-upload-description${step === LoaderStep.loading ? " file-upload-description--warning" : ""}`}
                            >
                                <span className="file-upload-description__text">
                                    {step === LoaderStep.loading
                                        ? LOADING_DESCRIPTION
                                        : step === LoaderStep.complete
                                          ? COMPLETE_DESCRIPTION
                                          : step === LoaderStep.start
                                            ? isTrial
                                                ? TRIAL_DESCRIPTION
                                                : START_DESCRIPTION
                                            : ""}
                                    {isTrial && step === LoaderStep.start && (
                                        <Link
                                            className="file-upload-description__text-link"
                                            to="/feedback"
                                        >
                                            Upgrade to full version
                                        </Link>
                                    )}
                                </span>
                            </div>
                            <div
                                className={`file-upload-content${drop ? " file-upload-content__drop" : ""}${files.length ? " file-upload-content__no-border" : ""}`}
                            >
                                {!files.length ? (
                                    <div className="file-upload-content-selection">
                                        <SvgIcon
                                            className="file-upload-content-selection__icon"
                                            component={UploadIcon}
                                            inheritViewBox
                                        />
                                        <span className="file-upload-content-selection__text">
                                            <span>drop file here of </span>
                                            <a onClick={openFilePicker}>click to upload</a>
                                        </span>
                                    </div>
                                ) : (
                                    <div className="file-upload-content-files">
                                        {files.map(file => (
                                            <div
                                                key={file.uid}
                                                className="file-upload-content-file"
                                            >
                                                <div
                                                    className={`file-upload-content-file__name${isErrorFileStatus(file.loadingStatus) ? " file-upload-content-file__name--error" : ""}`}
                                                >
                                                    <SvgIcon
                                                        component={DocumentIcon}
                                                        inheritViewBox
                                                    />
                                                    <span>{file.fileData.name}</span>
                                                </div>
                                                <ProgressBar
                                                    className="file-upload-content-file__progress-bar"
                                                    type={progressType(file.loadingStatus)}
                                                    title={fileSize(file.fileData.size)}
                                                    warningTitle={warningFileTitle(file.fileData)}
                                                    percentValue={file.loadingPercent}
                                                    height={36}
                                                    showClose={
                                                        !(
                                                            step !== LoaderStep.start &&
                                                            file.loadingStatus ===
                                                                UploadableFileStatus.unsupported
                                                        )
                                                    }
                                                    handleClose={() => handleCancelClick(file)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {step === LoaderStep.error && (
                        <div className="file-upload-error">
                            <SvgIcon
                                className="file-upload-error__icon"
                                component={DocumentErrorIcon}
                                inheritViewBox
                            />
                            <span className="file-upload-error__title">Error uploading</span>
                            <span className="file-upload-error__message">{error}</span>
                        </div>
                    )}
                    <div className="file-upload-action">
                        {step === LoaderStep.start && (
                            <>
                                <Button
                                    className="file-upload-action__button--half file-upload-action__button--light"
                                    variant="text"
                                    onClick={openFilePicker}
                                >
                                    Select File(s)
                                </Button>
                                <Button
                                    className="file-upload-action__button--half"
                                    variant="text"
                                    onClick={handleUploadFileClick}
                                    disabled={!isAvailableUpload()}
                                >
                                    Upload File(s)
                                </Button>
                            </>
                        )}
                        {(step === LoaderStep.loading || step === LoaderStep.complete) && (
                            <Button
                                variant="text"
                                onClick={handleCloseClick}
                                disabled={step === LoaderStep.loading}
                            >
                                {step === LoaderStep.loading ? "Please Wait" : "Close"}
                            </Button>
                        )}
                        {step === LoaderStep.error && (
                            <Button variant="text" onClick={handleTryAgainClick}>
                                Try Again
                            </Button>
                        )}
                    </div>
                </div>
            </ModalTemplate>
        </div>
    );
};

export default FileUploadView;
