import { Routes, Route, Navigate } from "react-router-dom";

import { SocialAuthCallback } from "../auth/social-auth-callback.comp";
import { SocialAuthError } from "../auth/social-auth-error.comp";
import { Layout } from "../layout/layout.comp";
import UnverifiedProtectedRoute from "./firebase/unverified-protected-route.comp";
import VerifiedProtectedRoute from "./firebase/verified-protected-route.comp";
import ProtectedRoute from "../routes/protected-route.comp";
import VerifiedSignInRoute from "../routes/verified-signin-route.comp";
import GoogleSignIn from "../../views/signin/signin-google";
import SignIn from "../../views/signin/signin";
import TrialSignUp from "../../views/signup/signup-trial";
import FirebaseSignIn from "../../views/admin/auth/signin/signin";
import FirebaseSignUp from "../../views/admin/auth/signup/signup";
import TrialFirebaseSignUp from "../../views/admin/auth/signup/signup-trial";
import ForgotPassword from "../../views/admin/auth/forgot-password/forgot-password";
import EmaiConfirm from "../../views/admin/auth/email-confirm/email-confirm";
import EmaiConfirmed from "../../views/admin/auth/email-confirmed/email-confirmed";
import ChatBot from "../../views/chat-bot/chat-bot";
import NotFound from "../../views/not-found/not-found";
import EntryPoint from "../../views/entry-point/entry-point";
import KnowledgeBaseElements from "../../views/admin/knowledge-base-elements/knowledge-base-elements";
import RecognitionResultView from "../../views/admin/recognition-result/recognition-result";
import CompareRecognitionResultView from "../../views/admin/compare-recognition-result/compare-recognition-result";
import LinkGoogleDriveView from "../../views/admin/link-google-drive/link-google-drive";
import FileUploadView from "../../views/admin/file-upload/file-upload";
import UploadedFilesView from "../../views/admin/uploaded-files/uploaded-files";
import PromptTuningView from "../../views/admin/prompt-tuning/prompt-tuning";
import TrialKnowledgeBaseView from "../../views/admin/trial-knowledge-base/trial-knowledge-base";
import TrialHomeView from "../../views/admin/trial-home/trial-home";
import InviteCodesView from "../../views/admin/invite-codes/invite-codes";
import FeedbackView from "../../views/admin/feedback/feedback";

enum RouteType {
    default,
    trial,
    comet
}

const AssistantRoutes = (type: RouteType) => {
    return (
        <Routes>
            {type === RouteType.comet ? (
                <>
                    <Route
                        path="/"
                        element={
                            <ProtectedRoute>
                                <Layout>
                                    <EntryPoint />
                                </Layout>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/login"
                        element={
                            <VerifiedSignInRoute>
                                <Layout>
                                    <GoogleSignIn />
                                </Layout>
                            </VerifiedSignInRoute>
                        }
                    />
                </>
            ) : (
                <>
                    <Route path="/" element={<Navigate to="/new-chat" />} />
                    <Route
                        path="/login"
                        element={
                            <VerifiedSignInRoute>
                                <SignIn />
                            </VerifiedSignInRoute>
                        }
                    />
                    <Route
                        path="/registration"
                        element={
                            <VerifiedSignInRoute>
                                <TrialSignUp />
                            </VerifiedSignInRoute>
                        }
                    />
                </>
            )}
            <Route
                path="/new-chat"
                element={
                    <ProtectedRoute>
                        <Layout>
                            <ChatBot isCreatingNewChat={true} />
                        </Layout>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/chat/:chatId"
                element={
                    <ProtectedRoute>
                        <Layout>
                            <ChatBot isCreatingNewChat={false} />
                        </Layout>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/404"
                element={
                    <ProtectedRoute>
                        <Layout>
                            <NotFound />
                        </Layout>
                    </ProtectedRoute>
                }
            />
            <Route path="/google-oauth-success-redirect">
                <Route path=":accessToken/:refreshToken/?" element={<SocialAuthCallback />} />
            </Route>
            <Route path="/google-oauth-error-redirect">
                <Route path=":code" element={<SocialAuthError />} />
            </Route>
            <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
    );
};

export const DefaultAssistantRoutes = () => {
    return AssistantRoutes(RouteType.default);
};

export const TrialAssistantRoutes = () => {
    return AssistantRoutes(RouteType.trial);
};

export const CometAssistantRoutes = () => {
    return AssistantRoutes(RouteType.comet);
};

const AdminRoutes = (type: RouteType) => {
    return (
        <Routes>
            {type === RouteType.trial ? (
                <>
                    <Route
                        path="/"
                        element={
                            <VerifiedProtectedRoute>
                                <Layout>
                                    <TrialHomeView />
                                </Layout>
                            </VerifiedProtectedRoute>
                        }
                    />
                    <Route
                        path="/uploaded-files"
                        element={
                            <VerifiedProtectedRoute>
                                <Layout>
                                    <TrialKnowledgeBaseView />
                                </Layout>
                            </VerifiedProtectedRoute>
                        }
                    />
                    <Route
                        path="/feedback"
                        element={
                            <VerifiedProtectedRoute>
                                <FeedbackView />
                            </VerifiedProtectedRoute>
                        }
                    />
                    <Route
                        path="/registration"
                        element={
                            <VerifiedSignInRoute>
                                <TrialFirebaseSignUp />
                            </VerifiedSignInRoute>
                        }
                    />
                </>
            ) : (
                <>
                    <Route path="/" element={<Navigate to="/knowledge-base" />} />
                    <Route
                        path="/knowledge-base"
                        element={
                            <VerifiedProtectedRoute>
                                <Layout>
                                    <KnowledgeBaseElements />
                                </Layout>
                            </VerifiedProtectedRoute>
                        }
                    />
                    <Route
                        path="/knowledge-base/:providerId"
                        element={
                            <VerifiedProtectedRoute>
                                <Layout>
                                    <KnowledgeBaseElements />
                                </Layout>
                            </VerifiedProtectedRoute>
                        }
                    />
                    <Route
                        path="/knowledge-base/:providerId/:courseId"
                        element={
                            <VerifiedProtectedRoute>
                                <Layout>
                                    <KnowledgeBaseElements />
                                </Layout>
                            </VerifiedProtectedRoute>
                        }
                    />
                    <Route
                        path="/compare-recognition-result/:providerId/:uid"
                        element={
                            <VerifiedProtectedRoute>
                                <Layout>
                                    <CompareRecognitionResultView />
                                </Layout>
                            </VerifiedProtectedRoute>
                        }
                    />
                    <Route
                        path="/link-google-drive"
                        element={
                            <VerifiedProtectedRoute>
                                <LinkGoogleDriveView />
                            </VerifiedProtectedRoute>
                        }
                    />
                    <Route
                        path="/uploaded-files"
                        element={
                            <VerifiedProtectedRoute>
                                <Layout>
                                    <UploadedFilesView />
                                </Layout>
                            </VerifiedProtectedRoute>
                        }
                    />
                    <Route
                        path="/registration"
                        element={
                            <VerifiedSignInRoute>
                                <FirebaseSignUp />
                            </VerifiedSignInRoute>
                        }
                    />
                </>
            )}
            {type === RouteType.default && (
                <Route
                    path="/prompt-tuning"
                    element={
                        <VerifiedProtectedRoute>
                            <Layout>
                                <PromptTuningView />
                            </Layout>
                        </VerifiedProtectedRoute>
                    }
                />
            )}
            <Route
                path="/recognition-result/:providerId/:uid"
                element={
                    <VerifiedProtectedRoute>
                        <Layout>
                            <RecognitionResultView />
                        </Layout>
                    </VerifiedProtectedRoute>
                }
            />
            <Route
                path="/files-upload"
                element={
                    <VerifiedProtectedRoute>
                        <FileUploadView />
                    </VerifiedProtectedRoute>
                }
            />
            <Route
                path="/404"
                element={
                    <VerifiedProtectedRoute>
                        <Layout>
                            <NotFound />
                        </Layout>
                    </VerifiedProtectedRoute>
                }
            />
            <Route
                path="/invite-codes"
                element={
                    <VerifiedProtectedRoute>
                        <InviteCodesView />
                    </VerifiedProtectedRoute>
                }
            />
            <Route
                path="/email-confirm"
                element={
                    <UnverifiedProtectedRoute>
                        <EmaiConfirm />
                    </UnverifiedProtectedRoute>
                }
            />
            <Route
                path="/login"
                element={
                    <VerifiedSignInRoute>
                        <FirebaseSignIn />
                    </VerifiedSignInRoute>
                }
            />
            <Route
                path="/forgot-password"
                element={
                    <VerifiedSignInRoute>
                        <ForgotPassword />
                    </VerifiedSignInRoute>
                }
            />
            <Route
                path="/registration-success"
                element={
                    <VerifiedProtectedRoute>
                        <EmaiConfirmed />
                    </VerifiedProtectedRoute>
                }
            />
            <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
    );
};

export const DefaultAdminRoutes = () => {
    return AdminRoutes(RouteType.default);
};

export const TrialAdminRoutes = () => {
    return AdminRoutes(RouteType.trial);
};

export const CometAdminRoutes = () => {
    return AdminRoutes(RouteType.comet);
};
