import { useState, ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";

import "./style.scss";
import { ReactComponent as EyeIcon } from "../../../../assets/eyeIcon.svg";
import { ReactComponent as EyeOffIcon } from "../../../../assets/eyeOffIcon.svg";
import { FirebaseErrorCode } from "../../../../common/enums/firebase-error-code";
import { FirebaseAuthContainer } from "../../../../common/containers/auth/firebase-auth-container.util";
import { emailRegex } from "../../../../common/utilities/constants/constants";
import { AuthTemplate } from "../../../../components/admin/auth-template/auth-template.comp";
import { Loader } from "../../../../components/loader/loader.comp";

const FirebaseSignUp = () => {
    const emailMask = new RegExp(process.env.REACT_APP_EMAIL_REGEX ?? emailRegex, "g");
    const [searchParams] = useSearchParams();
    const invite = searchParams.get("invite");
    const { signUp } = FirebaseAuthContainer.useContainer();
    const [login, setLogin] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [passwordType, setPasswordType] = useState<string>("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState<string>("password");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isInvalid, setIsInvalid] = useState<boolean>(false);

    const handleLoginChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const val = evt.target?.value;
        setLogin(val ?? "");
        setErrorMessage("");
        setIsInvalid(false);
    };

    const handlePasswordChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const val = evt.target?.value;
        setPassword(val ?? "");
        setErrorMessage("");
        setIsInvalid(false);
    };

    const handleConfirmPasswordChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const val = evt.target?.value;
        setConfirmPassword(val ?? "");
        setErrorMessage("");
        setIsInvalid(false);
    };

    const handlePasswordEyeClick = () => {
        setPasswordType(prevValue => (prevValue === "text" ? "password" : "text"));
    };

    const handleConfirmPasswordEyeClick = () => {
        setConfirmPasswordType(prevValue => (prevValue === "text" ? "password" : "text"));
    };

    const handleSignUnClick = () => {
        if (!login || !password || !confirmPassword) return;

        if (!login.match(emailMask)) {
            setIsInvalid(true);
            setErrorMessage("Invalid email format");
            return;
        } else if (password !== confirmPassword) {
            setIsInvalid(true);
            setErrorMessage("Passwords don't match. Check again");
            return;
        }

        setIsLoading(true);
        signUp(login, password, invite)
            .catch(error => {
                switch (error.code as FirebaseErrorCode) {
                    case FirebaseErrorCode.emailAlreadyInUse:
                        setIsInvalid(true);
                        setErrorMessage("This email address is already in use");
                        return;
                    case FirebaseErrorCode.weakPassword:
                        setIsInvalid(true);
                        setErrorMessage("Password should be at least 6 characters");
                        return;
                    default:
                        setErrorMessage("Something went wrong. Please try again later");
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <AuthTemplate height={417} title="Admin Panel" description="Welcome to TIVA Knowledge Base">
            <div className="admin-signup">
                <div className="admin-signup-title">Sign up</div>
                <div className="admin-signup-description">
                    <span className="admin-signup-description__text">Already have an account?</span>
                    <a href="/login" className="admin-signup-description__link">
                        Log in
                    </a>
                </div>
                <div className="admin-signup-content">
                    <div className="admin-signup-field">
                        <input
                            tabIndex={1}
                            type="text"
                            placeholder="Your Email"
                            onChange={handleLoginChange}
                            value={login}
                        />
                    </div>
                    <div className="admin-signup-field">
                        <input
                            className="admin-signup-field__input--eye"
                            tabIndex={2}
                            type={passwordType}
                            placeholder="Password"
                            onChange={handlePasswordChange}
                            value={password}
                        />
                        <SvgIcon
                            className="admin-signup-field__eye"
                            component={passwordType === "text" ? EyeIcon : EyeOffIcon}
                            inheritViewBox
                            onClick={handlePasswordEyeClick}
                        />
                    </div>
                    <div className="admin-signup-field">
                        <input
                            className="admin-signup-field__input--eye"
                            tabIndex={3}
                            type={confirmPasswordType}
                            placeholder="Confirm Password"
                            onChange={handleConfirmPasswordChange}
                            value={confirmPassword}
                        />
                        <SvgIcon
                            className="admin-signup-field__eye"
                            component={confirmPasswordType === "text" ? EyeIcon : EyeOffIcon}
                            inheritViewBox
                            onClick={handleConfirmPasswordEyeClick}
                        />
                    </div>
                    <div className="admin-signup-message">
                        <span>{errorMessage}</span>
                    </div>
                </div>
                <div className="admin-signup-action">
                    <Button
                        variant="text"
                        onClick={handleSignUnClick}
                        disabled={!login || !password || !confirmPassword || isLoading || isInvalid}
                    >
                        {!isLoading ? <span>Sign up</span> : <Loader size={24} borderWidth={4} />}
                    </Button>
                </div>
            </div>
        </AuthTemplate>
    );
};

export default FirebaseSignUp;
