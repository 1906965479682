export enum SourceType {
    // doc = "doc",
    // docx = "docx",
    // xls = "xls",
    // xlsx = "xlsx",
    txt = "txt",
    pdf = "pdf",
    mp3 = "mp3",
    mp4 = "mp4",
    file = "file",
    video = "video"
}
