export enum MimeType {
    // docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    // xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // xls = "application/vnd.ms-excel",
    // doc = "application/msword",
    pdf = "application/pdf",
    txt = "text/plain",
    mp3 = "audio/mpeg",
    mp4 = "video/mp4"
}

export enum TrialMimeType {
    docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    xls = "application/vnd.ms-excel",
    doc = "application/msword",
    pdf = "application/pdf",
    txt = "text/plain"
}
