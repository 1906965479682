import { useEffect } from "react";
import { Params, useParams } from "react-router-dom";

import "./style.scss";

export const SocialAuthCallback = () => {
    const { accessToken, refreshToken, from } = useParams<Params<string>>();

    useEffect(() => {
        setTimeout(() => {
            window.opener.authenticateCallback({ accessToken, refreshToken, from });
            window.close();
        }, 500);
    });

    return (
        <div className="social-auth-message">
            Authenticated successfully! You are being redirected...
        </div>
    );
};
