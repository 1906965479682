import {
    DefaultAssistantRoutes,
    TrialAssistantRoutes,
    CometAssistantRoutes,
    DefaultAdminRoutes,
    TrialAdminRoutes,
    CometAdminRoutes
} from "../../../components/routes/routers.comp";
import { AuthContainer } from "../../containers/auth/auth-container.util";
import { FirebaseAuthContainer } from "../../containers/auth/firebase-auth-container.util";
import { App } from "../../interfaces/comps/app.interface";
import { MenuItem } from "../../interfaces/comps/menu-item.interface";
import { Theme } from "../../enums/theme.enum";
import { Logo } from "../../enums/logo.enum";
import { FileType } from "../../enums/file-type.enum";

export const APPS: App[] = [
    {
        subdomains: ["trial"],
        routes: TrialAssistantRoutes,
        auth: AuthContainer,
        theme: Theme.tiva,
        logo: Logo.default,
        adminPanel: false,
        main: false
    },
    {
        subdomains: ["admin.trial"],
        routes: TrialAdminRoutes,
        auth: FirebaseAuthContainer,
        theme: Theme.tivaAdmin,
        logo: Logo.default,
        adminPanel: true,
        main: false
    },
    {
        subdomains: ["", "scottsdale"],
        routes: DefaultAssistantRoutes,
        auth: AuthContainer,
        theme: Theme.tiva,
        logo: Logo.scottsdale,
        adminPanel: false,
        main: false
    },
    {
        subdomains: ["admin", "admin.scottsdale"],
        routes: DefaultAdminRoutes,
        auth: FirebaseAuthContainer,
        theme: Theme.tivaAdmin,
        logo: Logo.scottsdale,
        adminPanel: true,
        main: false
    },
    {
        subdomains: ["mba", "fortmill"],
        routes: DefaultAssistantRoutes,
        auth: AuthContainer,
        theme: Theme.tiva,
        logo: Logo.default,
        adminPanel: false,
        main: true
    },
    {
        subdomains: ["admin.mba", "admin.fortmill"],
        routes: DefaultAdminRoutes,
        auth: FirebaseAuthContainer,
        theme: Theme.tivaAdmin,
        logo: Logo.default,
        adminPanel: true,
        main: false
    },
    {
        subdomains: ["dev.comet", "stage.comet", "demo.comet", "epic"],
        routes: CometAssistantRoutes,
        auth: AuthContainer,
        theme: Theme.comet,
        logo: Logo.comet,
        adminPanel: false,
        main: false
    },
    {
        subdomains: ["admin.dev.comet", "admin.stage.comet", "admin.demo.comet", "admin.epic"],
        routes: CometAdminRoutes,
        auth: FirebaseAuthContainer,
        theme: Theme.comet,
        logo: Logo.comet,
        adminPanel: true,
        main: false
    }
];

export const emailRegex = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const fileTypes: MenuItem<string>[] = Object.values(FileType).map(type => {
    switch (type) {
        case FileType.txt:
            return { key: "Txt", value: FileType.txt } as MenuItem<string>;
        case FileType.pdf:
            return { key: "Pdf", value: FileType.pdf } as MenuItem<string>;
        case FileType.mp3:
            return { key: "Mp3", value: FileType.mp3 } as MenuItem<string>;
        case FileType.mp4:
            return { key: "Mp4", value: FileType.mp4 } as MenuItem<string>;
    }
});

export const trialFileTypes: MenuItem<string>[] = [
    { key: "Txt", value: FileType.txt },
    { key: "Pdf", value: FileType.pdf }
];
