import { useState } from "react";
import { Params, useParams } from "react-router-dom";

import "./style.scss";

export const SocialAuthError = () => {
    const { code } = useParams<Params<string>>();
    const [message] = useState<string>(
        code === "403" ? "Invalid email format" : "Something went wrong. Please try again later"
    );

    return <div className="social-auth-message">{message}</div>;
};
