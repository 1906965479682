import { useState } from "react";
import { createContainer } from "unstated-next";

import { Logo } from "../../enums/logo.enum";
import { Theme } from "../../enums/theme.enum";
import { getAppTheme, getAppLogo } from "../../utilities/routes/routes.util";

function useTheme() {
    const [logo] = useState<Logo>(getAppLogo());
    const [theme] = useState<Theme>(getAppTheme());

    return {
        logo,
        theme
    };
}

export const ThemeContainer = createContainer(useTheme);
